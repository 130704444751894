import { forEach } from 'lodash';
import './bootstrap';
function errors_success(text) {
    swal(text, {
        icon: 'success',
        buttons: false,
        timer: 3000,
        closeOnClickOutside: false,
    })
}
function errors_info(text) {
    swal(text, {
        icon: 'info',
        buttons: false,
        timer: 5000,
        closeOnClickOutside: false,
    })
}
function errors_danger(text) {
    swal(text, {
        icon: 'error',
        buttons: false,
        timer: 3000,
        closeOnClickOutside: false,
    })
}
function errors_success_perma(text) {
    swal(text, {
        icon: 'success',
        buttons: false,
        timer: 29030400,
        closeOnClickOutside: false,
    })
}
function errors_info_perma(text) {
    swal(text, {
        icon: 'info',
        buttons: false,
        timer: 29030400,
        closeOnClickOutside: false,
    })
}
function errors_danger_perma(text) {
    swal(text, {
        icon: 'error',
        buttons: false,
        timer: 29030400,
        closeOnClickOutside: false,
    })
}
// Añadir articulos al ticket
$(".articles").on('click', function () {
    var amount;
    amount = $(this).children().eq(0).html();
    amount++;
    $(this).children().eq(0).html(amount);
    showticket($(this).data('id'), amount);
});
function showticket(id, amount) {
    var is_true;
    var price_total_amount;
    $.ajax({
        url: "/ticket/crear",
        type: "get",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: { 'id': id },
        dataType: 'json',
        success: function (data) {
            if (data.errors) {
                errors_danger(data.errors.id[0]);
            } else {
                $(".articles-ticket>tr").each(function () {
                    if ($(this).data('id') === id) {
                        $(this).children().eq(1).html(amount);
                        price_total_amount = data.price * amount
                        $(this).children().eq(3).html(price_total_amount);
                        is_true = true;
                    }
                });
                if (is_true != true) {
                    $(".articles-ticket").append("<tr  data-id=" + id + " data-type=" + data.type.id + " data-category=" + data.category_id + "><td>" + data.type.name + " " + data.article.description + "</td><td>" + amount + "</td><td>" + data.price + "</td><td>" + data.price + "</td></tr>");
                }
                let total_services = 0;
                let total_products = 0;
                $(".articles-ticket>tr").each(function () {
                    if ($(this).data('category') == 1) {
                        total_services += parseInt($(this).children().eq(3).html());
                    }
                    if ($(this).data('category') == 2) {
                        total_products += parseInt($(this).children().eq(3).html());
                    }
                });
                $('.total_services').html(total_services);
                $('.total_product').html(total_products);
                $('.total_all').html(total_services + total_products);
            }

        }
    });

}
$('#create_ticket').on('click', function () {
    var articles = new Array();
    $(".articles-ticket>tr").each(function () {
        articles.push({ "id": $(this).data('id'), "amount": $(this).children().eq(1).html(), "price_unit": $(this).children().eq(2).html() });
    });
    $.ajax({
        url: "/ticket/storage",
        type: "POST",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'price_total': $('.total_all').html(),
            'articles': articles
        },
        dataType: 'json',
        success: function (res) {
            $(".articles").each(function () {
                $(this).children().eq(0).html('');
            });
            $('.total_services').html('0');
            $('.total_product').html('0');
            $('.total_all').html('0');
            $('.articles-ticket').html('');
            errors_success("Ticket creado correctamente.");
        }
    });
});
$('#vaciar_ticket').on('click', function () {
    $(".articles").each(function () {
        $(this).children().eq(0).html('');
    });
    $('.total_services').html('0');
    $('.total_product').html('0');
    $('.total_all').html('0');
    $('.articles-ticket').html('');
    errors_success("Ticket vaciado correctamente.");
});
$('.delete_ticket').on('click', function () {
    var ticket = $(this).parent().parent().children().eq(0).html();
    var tic = $(this);
    swal({
        title: "Borrar el ticket?",
        text: "Se borrarán todos los datos del ticket",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then(function (r) {
        if (r) {
            $.ajax({
                url: '/ticket/delete',
                type: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: {
                    'id': ticket
                },
                dataType: 'JSON',
                success: function (res) {
                    if (res) {
                        swal("El ticket ha sido borrada correctamente.", {
                            icon: "success",
                            buttons: false,
                            timer: 5000,
                        });
                        tic.parent().parent().remove();
                    } else {
                        swal("Algo no ha salido correctamente", {
                            icon: "danger",
                            buttons: false,
                            timer: 5000,
                        });
                    }
                }
            });
        }
    });
});
//  CATEGORIAS
$('#category_add').click(function () {
    $('#categorys_errors').html('');
    $('#category_name').val('');
    $('#category-modal').modal('show');
});
$('#create_category').click(function () {
    $('#categorys_errors').html('');
    $.ajax({
        url: '/category/storage',
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'name': $('#category_name').val()
        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                $('#categorys_errors').html(data);
            } else {
                $('#categorys').append("<tr><td hidden>" + res.id + "</td><td>" + res.name + "</td><td><i class='fa-solid fa-pencil edit_category pe-1'></i><i class='fa-solid fa-square-xmark delete_category'></i></td></tr>");
                edit_category();
                delete_category();
                $('#category-modal').modal('hide');
                errors_success("Categoria creado correctamente.");
            }
        }
    });
});
function edit_category() {
    $('.edit_category').click(function () {
        $('#categorys_errors_edit').html('');
        $('#update_category').attr('data-id', $(this).parent().parent().children().eq(0).html());
        $('#category_name_edit').val($(this).parent().parent().children().eq(1).html());
        $('#category-modal-edit').modal('show');
    });
}
edit_category();
$('#update_category').click(function () {
    $('#categorys_errors_edit').html('');
    $.ajax({
        url: '/category/update',
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'id': $('#update_category').attr('data-id'),
            'name': $('#category_name_edit').val()
        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                if (res.errors.id) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.id + "</div>";
                }
                $('#categorys_errors_edit').html(data);
            } else {
                var data = '';
                res.forEach(category => {
                    data += "<tr><td hidden>" + category.id + "</td><td>" + category.name + "</td><td><i class='fa-solid fa-pencil edit_category pe-1'></i><i class='fa-solid fa-square-xmark delete_category'></i></td></tr>";
                });
                $('#categorys').html(data);
                edit_category();
                delete_category();
                $('#category-modal-edit').modal('hide');
                errors_success("Categoria actualizado correctamente.");
            }
        }
    });
});
function delete_category() {
    $('.delete_category').click(function () {
        var category = $(this).parent().parent().children().eq(0).html();
        var bor = $(this);
        swal({
            title: "Borrar el categoria?",
            text: "Se borrarán todos los datos del categoria",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(function (r) {
            if (r) {
                $.ajax({
                    url: '/category/delete',
                    type: 'delete',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: {
                        'id': category
                    },
                    dataType: 'JSON',
                    success: function (res) {
                        if (res) {
                            swal("El Categoria ha sido borrada correctamente.", {
                                icon: "success",
                                buttons: false,
                                timer: 5000,
                            });
                            bor.parent().parent().remove();
                        } else {
                            swal("Algo no ha salido correctamente", {
                                icon: "danger",
                                buttons: false,
                                timer: 5000,
                            });
                        }
                    }
                });
            }
        });
    });
}
delete_category();
// MARCAS
$('#mark_add').click(function () {
    $('#marks_errors').html('');
    $('#mark_name').val('');
    $('#mark-modal').modal('show');
});
$('#create_mark').click(function () {
    $('#marks_errors').html('');
    $.ajax({
        url: '/mark/storage',
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'name': $('#mark_name').val()
        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                $('#marks_errors').html(data);
            } else {
                $('#marks').append("<tr><td hidden>" + res.id + "</td><td>" + res.name + "</td><td><i class='fa-solid fa-pencil edit_mark pe-1'></i><i class='fa-solid fa-square-xmark delete_mark'></i></td></tr>");
                edit_mark();
                delete_mark();
                $('#mark-modal').modal('hide');
                errors_success("La marca se ha creado correctamente.");
            }
        }
    });
});
function edit_mark() {
    $('.edit_mark').click(function () {
        $('#marks_errors_edit').html('');
        $('#update_mark').attr('data-id', $(this).parent().parent().children().eq(0).html());
        $('#mark_name_edit').val($(this).parent().parent().children().eq(1).html());
        $('#mark-modal-edit').modal('show');
    });
}
edit_mark();
$('#update_mark').click(function () {
    $('#marks_errors_edit').html('');
    $.ajax({
        url: '/mark/update',
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'id': $('#update_mark').attr('data-id'),
            'name': $('#mark_name_edit').val()

        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                if (res.errors.id) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.id + "</div>";
                }
                $('#marks_errors_edit').html(data);
            } else {
                var data = '';
                res.forEach(mark => {
                    data += "<tr><td hidden>" + mark.id + "</td><td>" + mark.name + "</td><td><i class='fa-solid fa-pencil edit_mark pe-1'></i><i class='fa-solid fa-square-xmark delete_mark'></i></td></tr>";
                });
                $('#marks').html(data);
                edit_mark();
                delete_mark();
                $('#mark-modal-edit').modal('hide');
                errors_success("La marca se ha actualizado correctamente.");
            }
        }
    });
});
function delete_mark() {
    $('.delete_mark').click(function () {
        var mark = $(this).parent().parent().children().eq(0).html();
        var bor = $(this);
        swal({
            title: "Borrar la marca?",
            text: "Se borrarán todos los datos de la marca",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(function (r) {
            if (r) {
                $.ajax({
                    url: '/mark/delete',
                    type: 'delete',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: {
                        'id': mark
                    },
                    dataType: 'JSON',
                    success: function (res) {
                        if (res) {
                            swal("El marca ha sido borrada correctamente.", {
                                icon: "success",
                                buttons: false,
                                timer: 5000,
                            });
                            bor.parent().parent().remove();
                        } else {
                            swal("Algo no ha salido correctamente", {
                                icon: "danger",
                                buttons: false,
                                timer: 5000,
                            });
                        }
                    }
                });
            }
        });
    });
}
delete_mark();
// TIPOS
$('#type_add').click(function () {
    $('#types_errors').html('');
    $('#type_name').val('');
    $('#type-modal').modal('show');
});
$('#create_type').click(function () {
    $('#types_errors').html('');
    $.ajax({
        url: '/type/storage',
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'name': $('#type_name').val()
        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                $('#types_errors').html(data);
            } else {
                $('#types').append("<tr><td hidden>" + res.id + "</td><td>" + res.name + "</td><td><i class='fa-solid fa-pencil edit_type pe-1'></i><i class='fa-solid fa-square-xmark delete_type'></i></td></tr>");
                edit_type();
                delete_type();
                $('#type-modal').modal('hide');
                errors_success("El Tipo se ha creado correctamente.");
            }
        }
    });
});
function edit_type() {
    $('.edit_type').click(function () {
        $('#types_errors_edit').html('');
        $('#update_type').attr('data-id', $(this).parent().parent().children().eq(0).html());
        $('#type_name_edit').val($(this).parent().parent().children().eq(1).html());
        $('#type-modal-edit').modal('show');
    });
}
edit_type();
$('#update_type').click(function () {
    $('#types_errors_edit').html('');
    $.ajax({
        url: '/type/update',
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'id': $('#update_type').attr('data-id'),
            'name': $('#type_name_edit').val(),

        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                if (res.errors.id) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.id + "</div>";
                }
                $('#types_errors_edit').html(data);
            } else {
                var data = '';
                res.forEach(type => {
                    data += "<tr><td hidden>" + type.id + "</td><td>" + type.name + "</td><td><i class='fa-solid fa-pencil edit_type pe-1'></i><i class='fa-solid fa-square-xtype delete_type'></i></td></tr>";
                });
                $('#types').html(data);
                edit_type();
                delete_type();
                $('#type-modal-edit').modal('hide');
                errors_success("El tipo se ha actualizado correctamente.");
            }
        }
    });
});
function delete_type() {
    $('.delete_type').click(function () {
        var type = $(this).parent().parent().children().eq(0).html();
        var bor = $(this);
        swal({
            title: "Borrar el tipo?",
            text: "Se borrarán todos los datos de el tipo",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(function (r) {
            if (r) {
                $.ajax({
                    url: '/type/delete',
                    type: 'delete',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: {
                        'id': type
                    },
                    dataType: 'JSON',
                    success: function (res) {
                        if (res) {
                            swal("El tipo ha sido borrada correctamente.", {
                                icon: "success",
                                buttons: false,
                                timer: 5000,
                            });
                            bor.parent().parent().remove();
                        } else {
                            swal("Algo no ha salido correctamente", {
                                icon: "danger",
                                buttons: false,
                                timer: 5000,
                            });
                        }
                    }
                });
            }
        });
    });
}
delete_type();
// ARTICULOS
$('#article_add').click(function () {
    $.ajax({
        url: '/article/get',
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {},
        dataType: 'JSON',
        success: function (res) {
            var data = '<option disabled>Elije Tipo</option>';
            res.types.forEach(type => {
                data += "<option value='" + type.id + "'>" + type.name + "</option>";
            });
            $('#select_type_create').html(data);
            $('#articles_errors').html('');
            $('#article_name').val('');
            $('#article-modal').modal('show');
        }
    });
});
$('#create_article').click(function () {
    $('#articles_errors').html('');
    $.ajax({
        url: '/article/storage',
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'name': $('#article_name').val(),
            'type': $('#select_type_create').val()
        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                $('#articles_errors').html(data);
            } else {
                var data = '';
                res.articles.forEach(article => {
                    var entra = false;
                    data += "<tr><td hidden>" + article.id + "</td>";
                    if (article.category != null) {
                        data += "<td>" + article.category.name + "</td>";
                    } else {
                        data += "<td>-</td>";
                    }
                    res.marks.forEach(mark => {
                        if (mark.id == article.mark_id) {
                            data += "<td>" + mark.name + "</td>";
                            entra = true;
                        }
                    });
                    if (entra == false) {
                        data += "<td>-</td>";
                    }
                    data += "<td>" + article.type.name + ' ' + article.description + "</td>";
                    if (article.prices.length >= 1) {
                        var paso = article.prices.length - 1;
                        data += "<td>" + article.prices[paso].price + "</td>";
                    } else {
                        data += "<td>-</td>";
                    }
                    data += "<td><i class='fa-solid fa-euro-sign change_price pe-1'></i>";
                    if (article.category_id == 2) {
                        data += "<i class='fa-solid fa-bookmark assing_mark pe-1'></i>";
                    }
                    data += "<i class='fa-solid fa-layer-group change_category pe-1'></i><i class='fa-solid fa-pencil edit_article pe-1'></i><i class='fa-solid fa-square-xmark delete_article'></i></td></tr>";
                });
                $('#articles').html(data);
                changeprice();
                changecategory();
                edit_article();
                delete_article();
                $('#article-modal').modal('hide');
                errors_success("El articulo se ha creado correctamente.");
            }
        }
    });
});
function edit_article() {
    $('.edit_article').click(function () {
        var id = $(this).parent().parent().children().eq(0).html();
        $('#update_article').attr('data-id', $(this).parent().parent().children().eq(0).html()); 
        var name=$(this).parent().parent().children().eq(3).html().split(" ");
        var vuelta=0;
        var nam='';
        var max=name.length;
        name.forEach(na => {
            if (vuelta != 0 && vuelta < max) {
                nam+=na+" ";
            }
            vuelta++;
        });
        $('#article_name_edit').val(nam);
        $.ajax({
            url: '/article/get',
            type: 'GET',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                'id': id
            },
            dataType: 'JSON',
            success: function (res) {
                var data = '';
                res.types.forEach(type => {
                    if (res.type_actual == type.id) {
                        data += "<option selected value='" + type.id + "'>" + type.name + "</option>";
                    } else {
                        data += "<option value='" + type.id + "'>" + type.name + "</option>";
                    }
                });
                $('#select_type_edit').html(data);
                $('#articles_errors_edit').html('');
                $('#article-modal-edit').modal('show');
            }
        });
    });
}
edit_article();
$('#update_article').click(function () {
    $('#articles_errors_edit').html('');
    $.ajax({
        url: '/article/update',
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'id': $('#update_article').attr('data-id'),
            'name': $('#article_name_edit').val(),
            'type': $('#select_type_edit').val()
        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.name) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                if (res.errors.id) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.id + "</div>";
                }
                $('#articles_errors_edit').html(data);
            } else {
                var data = '';
                res.articles.forEach(article => {
                    var entra = false;
                    data += "<tr><td hidden>" + article.id + "</td>";
                    if (article.category != null) {
                        data += "<td>" + article.category.name + "</td>";
                    } else {
                        data += "<td>-</td>";
                    }
                    res.marks.forEach(mark => {
                        if (mark.id == article.mark_id) {
                            data += "<td>" + mark.name + "</td>";
                            entra = true;
                        }
                    });
                    if (entra == false) {
                        data += "<td>-</td>";
                    }
                    data += "<td>" + article.type.name + ' ' + article.description + "</td>";
                    if (article.prices.length >= 1) {
                        var paso = article.prices.length - 1;
                        data += "<td>" + article.prices[paso].price + "</td>";
                    } else {
                        data += "<td>-</td>";
                    }
                    data += "<td><i class='fa-solid fa-euro-sign change_price pe-1'></i>";
                    if (article.category_id == 2) {
                        data += "<i class='fa-solid fa-bookmark assing_mark pe-1'></i>";
                    }
                    data += "<i class='fa-solid fa-layer-group change_category pe-1'></i><i class='fa-solid fa-pencil edit_article pe-1'></i><i class='fa-solid fa-square-xmark delete_article'></i></td></tr>";
                });
                $('#articles').html(data);
                changeprice();
                changecategory();
                edit_article();
                delete_article();
                $('#article-modal-edit').modal('hide');
                errors_success("El articulo se ha actualizado correctamente.");
            }
        }
    });
});
function delete_article() {
    $('.delete_article').click(function () {
        var article = $(this).parent().parent().children().eq(0).html();
        var bor = $(this);
        swal({
            title: "Borrar el articulo?",
            text: "Se borrarán todos los datos de el articulo",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(function (r) {
            if (r) {
                $.ajax({
                    url: '/article/delete',
                    type: 'delete',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: {
                        'id': article
                    },
                    dataType: 'JSON',
                    success: function (res) {
                        if (res) {
                            swal("El articulo ha sido borrado correctamente.", {
                                icon: "success",
                                buttons: false,
                                timer: 5000,
                            });
                            bor.parent().parent().remove();
                        } else {
                            swal("Algo no ha salido correctamente", {
                                icon: "danger",
                                buttons: false,
                                timer: 5000,
                            });
                        }
                    }
                });
            }
        });
    });
}
delete_article();
// Cambiar categoria en el articulo
function changecategory() {
    var _this = '';
    $('.change_category').on('click', function () {
        _this = $(this);
        $('#apply_change_category').attr('data-article', $(this).parent().parent().children().eq(0).html());
        $.ajax({
            url: '/category/get',
            type: 'GET',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {},
            dataType: 'JSON',
            success: function (res) {
                var data = '';
                res.forEach(category => {
                    data += "<option value='" + category.id + "'>" + category.name + "</option>";
                });
                $('#select_category_change').html(data);
                $('#category-modal-change').modal('show');
            }
        });
    });
    $('#apply_change_category').on('click', function () {
        $.ajax({
            url: '/article/change/category',
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                'article': $('#apply_change_category').attr('data-article'),
                'category': $('#select_category_change').val()
            },
            dataType: 'JSON',
            success: function (res) {
                _this.parent().parent().children().eq(1).html(res.name);
                if (res.name == "Producto") {
                    var data=" <i class='fa-solid fa-euro-sign change_price pe-1'></i><i class='fa-solid fa-bookmark change_mark pe-1'></i><i class='fa-solid fa-layer-group change_category pe-1'></i><i class='fa-solid fa-pencil edit_article pe-1'></i><i class='fa-solid fa-square-xmark delete_article pe-1'></i>";
                } else {
                    var data=" <i class='fa-solid fa-euro-sign change_price pe-1'></i><i class='fa-solid fa-layer-group change_category pe-1'></i><i class='fa-solid fa-pencil edit_article pe-1'></i><i class='fa-solid fa-square-xmark delete_article pe-1'></i>";
                }
                _this.parent().parent().children().eq(5).html(data);
                changemark();
                changeprice();
                changecategory();
                edit_article();
                delete_article();
                $('#category-modal-change').modal('hide');
                errors_success("Categoria asignada correctamente.")
            }
        });
    });
}
changecategory();
// Cambiar precio
function changeprice() {
    var _this = '';
    $('.change_price').on('click', function () {
        $('#new_price').val('');
        _this = $(this);
        $('#apply_change_price').attr('data-article', $(this).parent().parent().children().eq(0).html());
        $('#price-modal-change').modal('show');
    });
    $('#apply_change_price').on('click', function () {
        $.ajax({
            url: '/article/change/price',
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                'article': $('#apply_change_price').attr('data-article'),
                'price': $('#new_price').val()
            },
            dataType: 'JSON',
            success: function (res) {
                _this.parent().parent().children().eq(4).html(res + "€");
                $('#price-modal-change').modal('hide');
                errors_success("Precio creado correctamente.")
            }
        });
    });
}
changeprice();
// Cambiar Marca en el Producto
function changemark() {
    var _this = '';
    $('.change_mark').on('click', function () {
        _this = $(this);
        $('#apply_change_mark').attr('data-article', $(this).parent().parent().children().eq(0).html());
        $.ajax({
            url: '/mark/get',
            type: 'GET',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {},
            dataType: 'JSON',
            success: function (res) {
                var data = "<option value='0'>Sin Marca</option>";
                res.forEach(mark => {
                    data += "<option value='" + mark.id + "'>" + mark.name + "</option>";
                });
                $('#select_mark_change').html(data);
                $('#mark-modal-change').modal('show');
            }
        });
    });
    $('#apply_change_mark').on('click', function () {
        $.ajax({
            url: '/article/change/mark',
            type: 'GET',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {
                'article': $('#apply_change_mark').attr('data-article'),
                'mark': $('#select_mark_change').val()
            },
            dataType: 'JSON',
            success: function (res) {
                _this.parent().parent().children().eq(2).html(res);
                $('#mark-modal-change').modal('hide');
                errors_success("Marca asignada correctamente.");
            }
        });
    });
}
changemark();
$('.generate-pdf-view').on('click', function () {
    $('#create_pdf_errors').html('');
    $('#año').val('');
    $('#dia').val('');
    $('#select_mes').val('');
    $('#download-pdf').addClass("d-none");
    $('#showpdf-modal').modal('show');
});
$('#generate_pdf').on('click', function () {
    $.ajax({
        url: '/generate-pdf',
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            'dia': $('#dia').val(),
            'mes': $('#select_mes').val(),
            'año': $('#año').val()
        },
        dataType: 'JSON',
        success: function (res) {
            if (res.errors) {
                var data = '';
                if (res.errors.dia) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.name + "</div>";
                }
                if (res.errors.mes) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.mes + "</div>";
                }
                if (res.errors.año) {
                    data += "<div class='alert alert-danger p-2' role='alert'>" + res.errors.año + "</div>";
                }
                $('#create_pdf_errors').html(data);
            } else {
                $('#download-pdf').attr("href", "/storage/Peluqueria-" + res.mes + "-" + res.año + ".pdf");
                $('#download-pdf').removeClass("d-none");
                errors_success("PDF generado correctamente.");
            }
        }
    });
});